import { create } from "zustand";
import { useThemeSlice } from "./theme/theme.slice";
import { persist } from "zustand/middleware";
import { lsAppearanceSettingsKey } from "./constants";
import { useCurrencySlice } from "./currency/currency.slice";

//Since we may have different, more complex settings for appearance beyond theme,
//it's best to keep setting data here and create separate services for each setting
//to keep it modular
export const useAppearanceSettingsStore = create(
    persist(
        (set) => ({
            ...useThemeSlice(set),
            ...useCurrencySlice(set),
            loadingAppearanceSettings: false,
            errorLoadingAppearanceSettings: false,
            setLoading: (loadingAppearanceSettings) =>
                set({
                    loadingAppearanceSettings,
                }),
            setError: (errorLoadingAppearanceSettings) =>
                set({
                    errorLoadingAppearanceSettings,
                }),
            loadData: (data) => set((state) => ({ ...state, ...data })),
        }),
        {
            name: lsAppearanceSettingsKey,
            version: 1,
            //exclude data loading status params for storing
            partialize: (state) => {
                const {
                    loadingAppearanceSettings,
                    errorLoadingAppearanceSettings,
                    ...toPersist
                } = state;
                return toPersist;
            },
        },
    ),
);
