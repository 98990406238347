import { useHookIndexerForLogging } from "./hookIndexer.store";
import { createContext, useCallback, useEffect, useMemo, useRef } from "react";
import { proxyForUseState } from "./useStateProxyForLogging";
import { inLoggerMode } from "../../globalConsts.constants";

export const useLoggerService = () => {
    const useLatestIndexOfStateToLog = (componentName) => {
        return useMemo(() => {
            const store = useHookIndexerForLogging.getState();
            store.updateIndexer(componentName);
            return store.hookIndexByRegisteredComponentName[componentName] || 0;
        }, []);
    };

    useMemo(() => {
        proxyForUseState();
    }, []);

    return useCallback(useLatestIndexOfStateToLog, []);
};

export const LoggerService = createContext({ loggerService: null });

export const LoggerServiceProvider = ({ children }) => {
    const loggerService = useLoggerService();

    return (
        <LoggerService.Provider value={{ loggerService }}>
            {children}
        </LoggerService.Provider>
    );
};

export const LoggerServiceProviderGuard = ({ children }) => {
    //prevent logging from happening in production
    return inLoggerMode ? (
        <LoggerServiceProvider>{children}</LoggerServiceProvider>
    ) : (
        children
    );
};
