import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RootPaths } from "../fantasy-stocks-router";
import { Box } from "@mui/joy";
import SettingsRouter, {
    RootSettingsPath,
} from "../../screens/Settings/setings-router";
import { AuthState } from "../../state/auth.context";
import { AccountProvider } from "../../state/account.context";
import { AppearanceSettingsServiceProvider } from "../../store/settings/appearance/appearanceSettingsService.context";
import { AwardsServiceProvider } from "../../state/awards.context";
import { GlobalStylesProvider } from "../../state/globalStyles.context";
import { ExperienceServiceProvider } from "../../state/experience.context";

export const AppGuardComponent = ({ children }) => {
    const { sessionState } = useContext(AuthState);
    const isLoggedIn = sessionState.token ? true : false;

    //kick the user out of the main app after logout/if not logged in
    return isLoggedIn === false ? (
        <Navigate to={RootPaths.MAIN_ROOT} />
    ) : (
        <AccountProvider>
            <ExperienceServiceProvider>
                <AwardsServiceProvider>
                    <AppearanceSettingsServiceProvider>
                        <GlobalStylesProvider>
                            <MainContent />
                        </GlobalStylesProvider>
                    </AppearanceSettingsServiceProvider>
                </AwardsServiceProvider>
            </ExperienceServiceProvider>
        </AccountProvider>
    );
};

function MainContent() {
    //If the user wants to go into settings, we have to hide the
    //main dashboard but still render whatever screen we are at
    //in the main dashboard (i.e. what Outlet resolves to) so it's
    //ready when the user exits settings
    const location = useLocation();
    const userInSettings = location.hash
        ? location.hash.startsWith(RootSettingsPath)
        : false;
    const settingsContentDisplay = userInSettings ? "block" : "none";
    const normalContentDisplay = !userInSettings ? "block" : "none";

    return (
        <>
            <Box sx={{ display: settingsContentDisplay }}>
                <SettingsRouter />
            </Box>
            <Box sx={{ display: normalContentDisplay }}>
                <Outlet />
            </Box>
        </>
    );
}
