import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Typography,
} from "@mui/joy";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";

//This component allows the user to tweak between displaying numbers in
//their preferred formatting (i.e. thru settings or if it was coded in)
//and unformatted so more accurate data is displayed in the same way
//it's stored or computed

const FormatTargets = {
    ROW: "row",
    COLUMN: "column",
    SINGLE_CELL: "single cell",
};

const SelectedTypes = {
    FORMATTED: "formatted",
    UNFORMATTED: "unformatted",
};

function NumberFormatDisplayTool({
    row = 0,
    column = 0,
    updateFormatDisplay,
    formatTarget,
}) {
    const [showOptions, setShowOptions] = useState(false);

    const handleSelectionForSingleCell = (selection) => {
        switch (selection) {
            case SelectedTypes.FORMATTED:
                updateFormatDisplay(row, column, true);
                break;
            case SelectedTypes.UNFORMATTED:
                updateFormatDisplay(row, column, false);
                break;
            default: //unlikely since this is handled internally
                throw new Error(`invalid type`);
        }
    };

    const handleSelectionForColumn = (selection) => {
        switch (selection) {
            case SelectedTypes.FORMATTED:
                updateFormatDisplay(column, true);
                break;
            case SelectedTypes.UNFORMATTED:
                updateFormatDisplay(column, false);
                break;
            default: //unlikely since this is handled internally
                throw new Error(`invalid type`);
        }
    };

    const handleSelectionForRow = (selection) => {
        switch (selection) {
            case SelectedTypes.FORMATTED:
                updateFormatDisplay(row, true);
                break;
            case SelectedTypes.UNFORMATTED:
                updateFormatDisplay(row, false);
                break;
            default: //unlikely since this is handled internally
                throw new Error(`invalid type`);
        }
    };

    const handleSelection =
        formatTarget === FormatTargets.SINGLE_CELL
            ? handleSelectionForSingleCell
            : formatTarget === FormatTargets.COLUMN
              ? handleSelectionForColumn
              : handleSelectionForRow;

    return (
        <Box sx={{ position: "relative" }}>
            <IconButton
                sx={{
                    opacity: 0.8,
                    backgroundColor: "transparent",
                }}
                onClick={() => setShowOptions(!showOptions)}
            >
                {showOptions && <KeyboardArrowUp />}
                {!showOptions && <KeyboardArrowDown />}
            </IconButton>
            {showOptions && (
                <Box
                    sx={{
                        position: "absolute",
                        zIndex: 1,
                        backgroundColor: "white",
                    }}
                >
                    <List disablePadding>
                        <ListItem>
                            <Typography
                                sx={{ fontWeight: "bold", textWrap: "nowrap" }}
                            >
                                Number Format Options
                            </Typography>
                        </ListItem>
                        {[
                            SelectedTypes.FORMATTED,
                            SelectedTypes.UNFORMATTED,
                        ].map((type, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => {
                                    handleSelection(type);
                                    setShowOptions(false);
                                }}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "grey",
                                    },
                                }}
                            >
                                {type}
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    );
}

function CellFormatting({ row, column, updateFormatDisplay }) {
    return (
        <NumberFormatDisplayTool
            row={row}
            column={column}
            updateFormatDisplay={updateFormatDisplay}
            formatTarget={FormatTargets.SINGLE_CELL}
        />
    );
}

function ColumnFormatting({ column, updateFormatDisplay }) {
    return (
        <NumberFormatDisplayTool
            column={column}
            updateFormatDisplay={updateFormatDisplay}
            formatTarget={FormatTargets.COLUMN}
        />
    );
}

function RowFormatting({ row, updateFormatDisplay }) {
    return (
        <NumberFormatDisplayTool
            row={row}
            updateFormatDisplay={updateFormatDisplay}
            formatTarget={FormatTargets.ROW}
        />
    );
}

export default {
    CellFormatting,
    ColumnFormatting,
    RowFormatting,
};
