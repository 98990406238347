import { AppearanceSettingsTypes } from "../constants";

export const ThemeModes = {
    DARK: "dark",
    LIGHT: "light",
};

export const themeInitialState = {
    [AppearanceSettingsTypes.THEME]: { mode: ThemeModes.LIGHT },
};
