import { Box, Grid, Typography } from "@mui/joy";
import NumberFormatter from "../../../utilComponents/NumberFormatter";
import NumberFormatDisplayTools from "./NumberFormatDisplayTools";
import LoadingStatusSpinner, {
    SpinnerSizes,
} from "../../../utilComponents/loading/LoadingStatusSpinner";

const cellStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

const cellWidthInColumns = 4;

//for column labels
function ColumnLabelGridCell({
    column,
    label,
    isNumberColumn = true,
    updateFormatDisplay,
    backgroundColor = "transparent",
}) {
    return (
        <Grid xs={cellWidthInColumns} sx={{ ...cellStyles, backgroundColor }}>
            <Box>
                <Typography>{label}</Typography>
            </Box>
            {isNumberColumn && (
                <Box>
                    <NumberFormatDisplayTools.ColumnFormatting
                        column={column}
                        updateFormatDisplay={updateFormatDisplay}
                    />
                </Box>
            )}
        </Grid>
    );
}

function NumberGridCell({
    value,
    row,
    column,
    precision = false,
    makePercent = false,
    showFormatted = true,
    updateFormatDisplay,
    backgroundColor = "transparent",
    updating = false,
}) {
    return (
        <Grid xs={cellWidthInColumns} sx={{ ...cellStyles, backgroundColor }}>
            <Box sx={{ overflow: "scroll" }}>
                <LoadingStatusSpinner
                    loading={updating}
                    spinnerSize={SpinnerSizes.SMALL}
                >
                    {showFormatted && (
                        <NumberFormatter
                            value={value}
                            precision={precision}
                            makePercent={makePercent}
                        />
                    )}
                    {!showFormatted && value}
                </LoadingStatusSpinner>
            </Box>
            <Box>
                <NumberFormatDisplayTools.CellFormatting
                    row={row}
                    column={column}
                    updateFormatDisplay={updateFormatDisplay}
                />
            </Box>
        </Grid>
    );
}

export default {
    ColumnLabelGridCell,
    NumberGridCell,
};
