import { AppearanceSettingsTypes } from "../constants";

export const CurrencySettingsTypes = {
    PRECISION: "precision",
};

export const currencyInitialState = {
    [AppearanceSettingsTypes.CURRENCY]: {
        [CurrencySettingsTypes.PRECISION]: 2, //2 decimal places
    },
};
