import { Outlet, useParams } from "react-router";
import { Box, Breadcrumbs, Typography } from "@mui/joy";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { tournamentById } from "../api/tournaments.service";
import ISODateStringFormatter from "./utilComponents/ISODateStringFormatter";
import { localizations } from "../utils/timeStampFormatTools";

export default function TournamentDetail() {
    const { id } = useParams();
    const [tournament, setTournament] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        tournamentById(id)
            .then((res) => {
                if (res.error) {
                    setError(true);
                } else if (res.data) {
                    setTournament(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <>
            <Breadcrumbs
                separator={<KeyboardArrowLeft />}
                aria-label="breadcrumbs"
            >
                <Link to="/app">Dashboard</Link>
                <Link to="/app/tournaments">Tournaments</Link>
                <Typography>{id}</Typography>
            </Breadcrumbs>
            <>
                {loading === true ? (
                    "loading"
                ) : error === true ? (
                    "error"
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "2em",
                        }}
                    >
                        <Box>
                            <TournamentDescription tournament={tournament} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Outlet context={{ tournament }} />
                        </Box>
                    </Box>
                )}
            </>
        </>
    );
}

function TournamentDescription({ tournament }) {
    const {
        tournamentName,
        tournamentDuration,
        tournamentStart,
        tournamentEnd,
        experienceLevel,
        numberOfCards,
    } = tournament;

    return (
        <Box sx={{ "& h2,h3": { margin: 0 } }}>
            <Box sx={{ marginBottom: "1em" }}>
                <h2>
                    <b>{tournamentName}</b>
                </h2>
                <h3>
                    <b style={{ whiteSpace: "nowrap" }}>
                        {tournamentDuration} Tournament, from{" "}
                        <ISODateStringFormatter
                            isoDateString={tournamentStart}
                            timelocalization={localizations.US}
                        />{" "}
                        to{" "}
                        <ISODateStringFormatter
                            isoDateString={tournamentEnd}
                            timelocalization={localizations.US}
                        />
                    </b>
                </h3>
            </Box>
            <Box>
                <h3>
                    <b>Experience Level: {experienceLevel}</b>
                </h3>
                <h3>
                    <b>Number of Cards: {numberOfCards}</b>
                </h3>
            </Box>
        </Box>
    );
}
