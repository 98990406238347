import React, { ReactElement, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { RootPaths, UIRoutePaths } from "../fantasy-stocks-router";
import { AuthState } from "../../state/auth.context";
import { PersistTools } from "../../state/persistance.state";

export const LoginScreenGuardComponent = ({ children }) => {
    const { sessionState } = useContext(AuthState);
    const isLoggedIn = sessionState.token ? true : false;

    useEffect(() => {
        //Assume the user just logged out and needs to have
        //data wiped from persistance
        if (!isLoggedIn) {
            PersistTools.clearUserData();
        }
    }, [isLoggedIn]);

    //kick the user to the main app content if
    //a) user is already in the login page and logged in successfully
    //b) user tried to come here for some reason while logged in
    return isLoggedIn === true ? (
        <Navigate to={RootPaths.APP_ROOT} replace={true} />
    ) : (
        <Outlet />
    );
};
