import { APIPaths } from "./paths";
import { apiContext } from ".";
import { CONTEXT_OPTIONS } from "./helpers/APIContext";

export const getExperienceBalance = async () => {
    return await apiContext.get(APIPaths.EXPERIENCE_BALANCE, {
        [CONTEXT_OPTIONS.NEEDS_AUTH]: true,
    });
};

export const getExperienceSummary = async () => {
    return await apiContext.get(APIPaths.EXPERIENCE_SUMMARY, {
        [CONTEXT_OPTIONS.NEEDS_AUTH]: true,
    });
};
