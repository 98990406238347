import { Skeleton } from "@mui/joy";
import ErrorLoadingMessage from "./ErrorLoadingMessage";

export const SkeletonTypes = {
    CIRCULAR: "circular",
    RECTANGULAR: "rectangular",
    TEXT: "text",
};

//type loading = boolean
export default function LoadingStatusSkeleton(props) {
    const {
        loading,
        children,
        errorMsg = null,
        skeletonType = SkeletonTypes.RECTANGULAR,
        bgcolor = "transparent",
    } = props;

    //This ugly code "hides" (sets transparent) the default grey background color that
    //joy mui enforces on it's text based skeleton variant in case the user wants to
    //custom set the bg color of the text skeleton
    const sx = {
        width: "100%",
        height: "100%",
        bgcolor,
        ...(bgcolor !== "transparent" && skeletonType === SkeletonTypes.TEXT
            ? {
                  "&::before": {
                      bgcolor: "transparent",
                  },
              }
            : {}),
    };

    return (
        <>
            {loading && (
                <Skeleton
                    loading={loading}
                    variant={skeletonType}
                    animation={"wave"}
                    sx={sx}
                />
            )}
            {errorMsg && <ErrorLoadingMessage errorMessage={errorMsg} />}
            {!loading && !errorMsg && children}
        </>
    );
}
