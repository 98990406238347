export const formatNumber = ({
    value,
    precision = false,
    makePercent = false,
}) => {
    if (isNaN(value)) {
        throw new Error(
            `cannot format value: invalid value specified, ${value} is not a valid number`,
        );
    }

    let formatted;

    if (makePercent === true) {
        formatted = (value * 100).toFixed(precision) + "%";
    } else {
        formatted = value.toFixed(precision);
    }

    return formatted;
};
