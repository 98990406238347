import { createContext, useEffect, useMemo } from "react";
import { useThemeService } from "./theme/themeService.state";
import { useAppearanceSettingsStore } from "./appearanceSettings.store";
import { getAppearanceSettings } from "../../../api/settings.service";
import { lsAppearanceSettingsKey } from "./constants";
import { PersistTools } from "../../../state/persistance.state";
import { useCurrencyService } from "./currency/currencyService.state";

export const useAppeareanceSettingsService = () => {
    const useLoadingState = () =>
        useAppearanceSettingsStore((state) => state.loadingAppearanceSettings);
    const useErrorState = () =>
        useAppearanceSettingsStore(
            (state) => state.errorLoadingAppearanceSettings,
        );
    const useThemeServiceSubscription = () => useThemeService();
    const useCurrencyServiceSubscription = () => useCurrencyService();

    useEffect(() => {
        //Does the user alrady have data cached in ls?
        const fromStorage = PersistTools.thaw(lsAppearanceSettingsKey);

        if (!fromStorage) {
            const { loadData, setLoading, setError } =
                useAppearanceSettingsStore.getState();
            setLoading(true);
            getAppearanceSettings()
                .then((res) => {
                    const { data = null, error = null } = res;
                    if (error) {
                        setError(true);
                    } else {
                        loadData(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    return useMemo(
        () => ({
            subscribeToLoadingState: useLoadingState,
            subscribeToErrorState: useErrorState,
            subscribeToThemeService: useThemeServiceSubscription,
            subscribeToCurrencyService: useCurrencyServiceSubscription,
        }),
        [],
    );
};

export const AppearanceSettingsServiceContext = createContext();

export const AppearanceSettingsServiceProvider = ({ children }) => {
    const appearanceSettingsService = useAppeareanceSettingsService();

    return (
        <AppearanceSettingsServiceContext.Provider
            value={appearanceSettingsService}
        >
            {children}
        </AppearanceSettingsServiceContext.Provider>
    );
};
