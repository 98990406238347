import { PersistTools } from "../../../state/persistance.state";

export const AppearanceSettingsTypes = {
    CURRENCY: "currency",
    THEME: "theme",
};

export const lsAppearanceSettingsKey = PersistTools.appendFantasyStocksPrefix(
    "appearance-settings",
);
