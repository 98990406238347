import { useAppearanceSettingsStore } from "../appearanceSettings.store";
import { AppearanceSettingsTypes } from "../constants";
import { ThemeModes } from "./constants";

export const useThemeService = () => {
    const [themeState, setTheme] = [
        useAppearanceSettingsStore(
            (state) => state[AppearanceSettingsTypes.THEME],
        ),
        useAppearanceSettingsStore((state) => state.setTheme),
    ];

    const toggleTheme = () => {
        const { mode } = themeState;
        const curModeIsDark = mode === ThemeModes.DARK;
        const newMode = curModeIsDark ? ThemeModes.LIGHT : ThemeModes.DARK;

        setTheme(buildThemeState(newMode));
    };

    return { themeState, toggleTheme };
};

const buildThemeState = (mode) => {
    return { mode };
};
