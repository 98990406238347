import React, { useContext } from "react";
import { LoggerService } from "./loggerService.state";

export const proxyForUseState = () => {
    const { useMemo, useState: useStateOriginal } = React;

    React.useState = (initialState, registeredComponentName = null) => {
        const [stateValue, setStateValue] = useStateOriginal(initialState);
        const { loggerService } = useContext(LoggerService);

        //The index corresponds to the order of when the hooks are initialized
        //in the component and is used to differentiate states when logging.
        let hookIndex = registeredComponentName
            ? loggerService(registeredComponentName)
            : 0;

        //Log to keep track of updates of the state distinguished by its index (hookIndex)
        //Memo immediately so it prints in rendering order
        useMemo(() => {
            if (registeredComponentName) {
                console.log(
                    `In component ${registeredComponentName}, value of state ${hookIndex} is ${stateValue}`,
                );
            }
        }, [stateValue]);

        return [stateValue, setStateValue];
    };
};
