import { useContext } from "react";
import { AppearanceSettingsServiceContext } from "../store/settings/appearance/appearanceSettingsService.context";
import { CurrencySettingsTypes } from "../store/settings/appearance/currency/constants";

export const useDefaultPrecisionFromSettings = () => {
    const { subscribeToCurrencyService } = useContext(
        AppearanceSettingsServiceContext,
    );
    const { currencyState } = subscribeToCurrencyService();

    const precision = currencyState[CurrencySettingsTypes.PRECISION];

    return precision;
};
