import { createContext, useEffect, useState } from "react";
import { getAwards } from "../api/awards.service";
import { apiContext } from "../api";

export const useAwards = () => {
    const [loadingAwards, setLoadingAwards] = useState(true);
    const [errorRetrievingAwards, setErrorRetrievingAwards] = useState(false);
    const [awards, setAwards] = useState([]);

    const pushNewAward = (newAward) => {
        setAwards((state) => [...state, newAward]);
    };

    useEffect(() => {
        getAwards()
            .then((res) => {
                if (res.error) {
                    setErrorRetrievingAwards(true);
                } else if (res.data) {
                    setAwards(res.data);
                }
            })
            .finally(() => {
                setLoadingAwards(false);
            });
    }, []);

    return { loadingAwards, awards, errorRetrievingAwards, pushNewAward };
};

export const useNewAwardQueueForMessaging = () => {
    //This is so we know when a new award was granted to the user
    //and can be used by a component to notify the user that
    //it's been issued
    const [newAwardsQueue, setNewAwardsQueue] = useState([]);
    const [nextAvailable, setNextAvailable] = useState(null);

    const pushNewAward = (award) => {
        setNewAwardsQueue((state) => [...state, award]);
    };
    //We take from the front so we get around issues with
    //rapid state updates being batched in response to
    //serveral awards being pushed quickly
    const updateNextAvailable = () => {
        const [next, ...rest] = newAwardsQueue;
        setNewAwardsQueue((state) => [...rest]);
    };

    //This gets triggered when we change who lives in the first slot of the queue
    //either by removing the one in front when calling updateNextAvailable to get
    //the next item to message, or when we push a new award in the queue when it's
    //empty (pushNewAward)
    useEffect(() => {
        if (newAwardsQueue.length) {
            setNextAvailable(newAwardsQueue[0]);
        }
    }, [newAwardsQueue.length ? newAwardsQueue[0] : null]);

    return { newAwardsQueue, nextAvailable, pushNewAward, updateNextAvailable };
};

export const useAwardsService = () => {
    const awards = useAwards();
    const newAwardsQueue = useNewAwardQueueForMessaging();
    //Middleware to respond to a new award achieved by the user.
    //This assumes the api returns 'newAward' as an attached param
    //and isn't a truly working solution at this time.

    //TODO: discuss at some point how to handle the use case
    //when a new award is issued
    useEffect(() => {
        const newAwardMiddleware = apiContext.addResponseInterceptor(
            (response) => {
                if (response.data?.newAward) {
                    const { newAward } = response.data;
                    awards.pushNewAward(newAward);
                    newAwardsQueue.pushNewAward(newAward);
                }
                return response;
            },
        );

        return () => apiContext.ejectResponseInterceptor(newAwardMiddleware);
    }, []);

    const useAwardsSubscription = () => awards;
    const useNewAwardsQueueSubscription = () => newAwardsQueue;

    return {
        subscribeToAwards: useAwardsSubscription,
        subscribeToNewAwardsQueue: useNewAwardsQueueSubscription,
    };
};

export const AwardsService = createContext();

export const AwardsServiceProvider = ({ children }) => {
    const { subscribeToAwards, subscribeToNewAwardsQueue } = useAwardsService();
    return (
        <AwardsService.Provider
            value={{
                subscribeToAwards,
                subscribeToNewAwardsQueue,
            }}
        >
            {children}
        </AwardsService.Provider>
    );
};
