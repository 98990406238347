import { Box } from "@mui/joy";
import AwardIcon from "../../images/award.png";

export default function Award({ awardState }) {
    const { awardName } = awardState;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "1em",
            }}
        >
            <Box>
                <img src={AwardIcon} height={100} />
            </Box>
            <Box>{awardName}</Box>
        </Box>
    );
}
