import { useState } from "react";

//This is mainly used in an active tournament screen to help the user
//toggle between displaying certain numbers in their original preferred precision
//and their actual precision to see their real values. It uses a matrix form
//as it assumes the numbers are displayed in a grid like structure

//matrix[row][col] === true when we want to see the formatted value in this cell based on
//user's settings or developer's preferences, false when we want the full value with every
//decimal place intact

export const useNumberFormatMatrix = (rows, columns) => {
    const [matrix, setMatrix] = useState(
        new Array(rows).fill(null).map((item) => new Array(columns).fill(true)),
    );
    //type value = boolean
    //not optimal as the entire thing updates, but the grid size should be small enough 
    //to not drag performance down
    const setCell = (row, column, value) => {
        setMatrix((state) => {
            state[row][column] = value;
            return [...state];
        });
    };

    const setRow = (row, value) => {
        setMatrix((state) => {
            state[row] = state[row].map((item) => value);
            return [...state];
        });
    };

    const setColumn = (column, value) => {
        setMatrix((state) => {
            return state.map((row) => {
                row[column] = value;
                return row;
            });
        });
    };

    return { matrix, setCell, setRow, setColumn };
};
