import { useContext, useEffect, useRef } from "react";
import { AuthState } from "./auth.context";

//WARNING!!! In order for user data to be cleared upon logout,
//it MUST have a key that begins with this prefix in order to
//avoid clearning it manually or writing extra code

const fantasyStocksPrefix = "fantasy-stocks-";

const appendFantasyStocksPrefix = (key) => {
    if (key.startsWith(fantasyStocksPrefix)) {
        return key;
    }
    return `${fantasyStocksPrefix}${key}`;
};

const clearUserData = (fromSessionStorage = false) => {
    const storagePreference = fromSessionStorage
        ? sessionStorage
        : localStorage;

    Object.keys(storagePreference).forEach((key) => {
        if (key.startsWith(fantasyStocksPrefix)) {
            storagePreference.removeItem(key);
        }
    });
};

const persist = (key, data, preferSessionStorage = false) => {
    const storagePreference = preferSessionStorage
        ? sessionStorage
        : localStorage;

    if (typeof data === "object") {
        let stringified;

        try {
            stringified = JSON.stringify(data);
            storagePreference.setItem(key, stringified);
        } catch (e) {
            console.error(`failed to stringify for persisting ${key}`);
            console.error(e);
        }
    }
};

//convenient way to get persisted data so we don't manually check for existance
const thaw = (key, fromSessionStorage = false) => {
    const storageSource = fromSessionStorage ? sessionStorage : localStorage;
    let thawed = null;

    try {
        thawed = JSON.parse(storageSource.getItem(key));
    } catch (e) {
        console.error(`failed to retrieve data from cache for ${key}`);
        console.error(e);
    }

    return thawed || false;
};

//This makes it easier to handle persisting data in (local | session)storage
const usePersistance = (stateToPersist, key, preferSessionStorage = false) => {
    useEffect(() => {
        persist(key, stateToPersist, preferSessionStorage);
    }, [stateToPersist]);

    return stateToPersist;
};

export const PersistTools = {
    usePersistance,
    persist,
    thaw,
    clearUserData,
    appendFantasyStocksPrefix,
};
