import { formatNumber } from "../../utils/numberFormatTools";

export default function NumberFormatter({
    value,
    precision = false,
    makePercent = false,
}) {
    let valueConverted = 0;

    try {
        valueConverted = formatNumber({
            value,
            precision,
            makePercent,
        });
    } catch (e) {
        console.error("invalid value specified", value);
        valueConverted = "NaN";
    }

    return <>{valueConverted}</>;
}
