import { createContext, useEffect, useState } from "react";
import {
    getExperienceBalance,
    getExperienceSummary,
} from "../api/experience.service";
import { apiContext } from "../api";

//interface TransactionSummaryItem {id: number; amount: 5; txTimetamp: DateTime;}
//interface ExperienceSummary {balance: number; txs: TransactionSummaryItem;}

export const useExperienceBalance = () => {
    const [balance, setBalance] = useState(0);
    const [loadingBalance, setLoadingBalance] = useState(true);
    const [errorRetrievingBalance, setErrorRetrievingBalance] = useState(false);

    useEffect(() => {
        getExperienceBalance()
            .then((res) => {
                if (res.error) {
                    setErrorRetrievingBalance(true);
                } else if (res.data) {
                    const { balance } = res.data;
                    setBalance(balance);
                }
            })
            .finally(() => {
                setLoadingBalance(false);
            });
    }, []);

    const updateBalance = (transactionSummaryItems) => {
        const toAddToBalance = transactionSummaryItems.reduce((toAdd, item) => {
            return toAdd + item.amount;
        }, 0);

        setBalance((state) => state + toAddToBalance);
    };

    return {
        balance,
        loadingBalance,
        errorRetrievingBalance,
        updateBalance,
    };
};

//We don't load this at init as it's not really needed upfront
//and upfront fetching would slow the app down when the user logs in
export const useExperienceTransactionHistory = () => {
    const [transactionHistory, setTransactionHistory] = useState({});
    const [transactionHistoryLoaded, setTransactionHistoryLoaded] =
        useState(false);
    const [loadingTransactionHistory, setLoadingTransactionHistory] =
        useState(false);
    const [
        errorRetrievingTransactionHistory,
        setErrorRetrievingTransactionHistory,
    ] = useState(false);

    useEffect(() => {
        if (loadingTransactionHistory && !transactionHistoryLoaded) {
            getExperienceSummary()
                .then((res) => {
                    if (res.error) {
                        setErrorRetrievingTransactionHistory(true);
                    } else if (res.data) {
                        setTransactionHistory(
                            convertToTXObjectByID(res.data.txs),
                        );
                        setTransactionHistoryLoaded(true);
                    }
                })
                .finally(() => {
                    setLoadingTransactionHistory(false);
                });
        }
    }, [loadingTransactionHistory]);

    const beginTransactionHistoryFetch = () => {
        setLoadingTransactionHistory(true);
    };

    const getHistoryAsList = () => {
        return Object.keys(transactionHistory).map(
            (id) => transactionHistory[id],
        );
    };

    //type transactionHistoryUpdateData = TransactionSummaryItem[];
    const updateTransactionHistory = (transactionHistoryUpdateData) => {
        setTransactionHistory((state) => ({
            ...state,
            ...convertToTXObjectByID(transactionHistoryUpdateData),
        }));
    };

    const convertToTXObjectByID = (txArray) => {
        return txArray.reduce((txByID, tx) => {
            txByID[tx.id] = tx;
            return txByID;
        }, {});
    };

    return {
        transactionHistory,
        transactionHistoryLoaded,
        loadingTransactionHistory,
        errorRetrievingTransactionHistory,
        beginTransactionHistoryFetch,
        getHistoryAsList,
        updateTransactionHistory,
    };
};

//type experienceUpdates = TransactionSummarayItem[]
export const useExperienceService = () => {
    const experienceBalance = useExperienceBalance();
    const experienceTransactionHistory = useExperienceTransactionHistory();
    const useExperienceBalanceSubscription = () => experienceBalance;
    const useExperienceTransactionHistorySubscription = () =>
        experienceTransactionHistory;

    useEffect(() => {
        const experienceMiddleware = apiContext.addResponseInterceptor(
            (response) => {
                if (response.data?.experienceUpdates) {
                    const { experienceUpdates } = response.data;
                    experienceBalance.updateBalance(experienceUpdates);
                    experienceTransactionHistory.updateTransactionHistory(
                        experienceUpdates,
                    );
                }
                return response;
            },
        );

        return () => apiContext.ejectResponseInterceptor(experienceMiddleware);
    }, []);

    return {
        subscribeToExperienceBalance: useExperienceBalanceSubscription,
        subscribeToExperienceTransactionHistory:
            useExperienceTransactionHistorySubscription,
    };
};

export const ExperienceServiceProviderContext = createContext();

export const ExperienceServiceProvider = ({ children }) => {
    const {
        subscribeToExperienceBalance,
        subscribeToExperienceTransactionHistory,
    } = useExperienceService();

    return (
        <ExperienceServiceProviderContext.Provider
            value={{
                subscribeToExperienceBalance,
                subscribeToExperienceTransactionHistory,
            }}
        >
            {children}
        </ExperienceServiceProviderContext.Provider>
    );
};
