import { Box, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import { UIRoutePaths } from "../router/fantasy-stocks-router";
import { useContext } from "react";
import LoadingStatusSpinner, {
    SpinnerSizes,
} from "./utilComponents/loading/LoadingStatusSpinner";
import { AwardsService } from "@state/awards.context";
import Award from "./awardsComponents/AwardComponent";

export default function AwardsComponent() {
    const { subscribeToAwards } = useContext(AwardsService);
    const { loadingAwards, awards, errorRetrievingAwards } =
        subscribeToAwards();

    return (
        <>
            <Typography level="h3">Awards</Typography>
            <LoadingStatusSpinner
                loading={loadingAwards}
                errorMsg={
                    errorRetrievingAwards ? "error retrieving awards" : null
                }
                spinnerSize={SpinnerSizes.LARGE}
            >
                <Awards awards={awards} />
            </LoadingStatusSpinner>
            <Link to={UIRoutePaths.EXPERIENCE_HISTORY}>
                View Experience Points
            </Link>
        </>
    );
}

function Awards({ awards }) {
    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: "1em" }}>
            {awards.map((award) => (
                <Box key={award.id}>
                    <Award awardState={award} />
                </Box>
            ))}
        </Box>
    );
}
