import { useState, createContext, useEffect } from "react";
import { loginUser /*logoutUser*/ } from "../api/auth.service";

import { TOKEN_IN_LOCAL_STORAGE_LABEL } from "../globalConsts.constants";

//this is the session token that gets set after the user successfully logs in and
//persisted in local-storage
export const useSession = () => {
    const [session, setSession] = useState({
        token: localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL)
            ? localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL)
            : null,
        setSessionAfterLogin: (token) => {
            setSession({ ...session, token });
            localStorage.setItem(TOKEN_IN_LOCAL_STORAGE_LABEL, token);
        },
        clearSessionAfterLogout: () => {
            setSession({ ...session, token: null });
            localStorage.removeItem(TOKEN_IN_LOCAL_STORAGE_LABEL);
        },
    });

    return session;
};

export const useLogin = () => {
    const [loginState, setLoginState] = useState({
        accountLoginCorrect: false,
        accountLoginIncorrect: false,
        loginAttemptResponse: null,
        submitting: false,
        handleAccountLogin: async (
            userSubmission /*{email: string, password: string} */,
        ) => {
            setLoginState((state) => ({
                ...state,
                submitting: true,
            }));
            const authResponse = await loginUser(
                userSubmission.email,
                userSubmission.password,
            );
            const loginSucceeds = authResponse.status === 200;
            const loginFails = !loginSucceeds;
            setLoginState((state) => ({
                ...state,
                accountLoginCorrect: loginSucceeds,
                accountLoginIncorrect: loginFails,
                loginAttemptResponse: authResponse,
                submitting: false,
            }));
        },
    });

    return loginState;
};

//todo-use a similar pattern for useLogin
export const useLogout = () => {
    const [logoutState, setLogoutState] = useState({
        submittingLogout: false,
        logoutSucceeds: false,
        logoutFails: false,
        beginLogout: () => {
            setLogoutState({
                ...logoutState,
                submittingLogout: true,
            });
        },
    });

    //until we get this implemented on the backend
    const logoutUser = async () => ({ status: 200 });

    useEffect(() => {
        if (logoutState.submittingLogout === true) {
            logoutUser().then((res) => {
                setLogoutState({
                    ...logoutState,
                    submittingLogout: false,
                    logoutSucceeds: res.status === 200,
                    logoutFails: res.status !== 200,
                });
            });
        }
    }, [logoutState.submittingLogout]);

    return logoutState;
};

export const AuthState = createContext({
    sessionState: null,
    useLogin: null,
    useLogout: null,
});

export const AuthStateProvider = ({ children }) => {
    const sessionState = useSession();

    return (
        <AuthState.Provider
            value={{
                sessionState,
                useLogin,
                useLogout,
            }}
        >
            {children}
        </AuthState.Provider>
    );
};
