import { APIPaths } from "./paths";
import { apiContext } from ".";
import { CONTEXT_OPTIONS } from "./helpers/APIContext";
import { AppearanceSettingsTypes } from "../store/settings/appearance/constants";
import { ThemeModes } from "../store/settings/appearance/theme/constants";

export const getAppearanceSettings = async () => {
    const res = await apiContext.get(APIPaths.SETTINGS, {
        [CONTEXT_OPTIONS.NEEDS_AUTH]: true,
    });

    if (!res.error) {
        const { backgroundColor } = res.data;
        const themeMode =
            backgroundColor === "#ffffff" ? ThemeModes.LIGHT : ThemeModes.DARK;

        res.data = {
            [AppearanceSettingsTypes.THEME]: { mode: themeMode },
        };
    }

    return res;
};
