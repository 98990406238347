import { useState } from "react";
import { Snackbar } from "@mui/joy";

export default function ErrorLoadingMessage({
    errorMessage,
    displayDurationMS = 1000,
}) {
    //true by default since this only renders when we have an error
    //to display
    const [open, setOpen] = useState(true);

    return (
        <Snackbar
            variant="soft"
            size="lg"
            color="danger"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={(e) => setOpen(false)}
            open={open}
            autoHideDuration={displayDurationMS}
        >
            {errorMessage}
        </Snackbar>
    );
}
