import { useAppearanceSettingsStore } from "../appearanceSettings.store";
import { AppearanceSettingsTypes } from "../constants";
import { CurrencySettingsTypes } from "./constants";

export const useCurrencyService = () => {
    const currencyState = useAppearanceSettingsStore(
        (state) => state[AppearanceSettingsTypes.CURRENCY],
    );
    const setPrecision = useAppearanceSettingsStore(
        (state) => state.setPrecision,
    );

    const updatePrecision = (numDecimals) => {
        setPrecision({ [CurrencySettingsTypes.PRECISION]: numDecimals });
    };

    return { currencyState, updatePrecision };
};
