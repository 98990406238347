import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { RootPaths, UIRoutePaths } from "../fantasy-stocks-router";
import { AuthState } from "../../state/auth.context";

export const RootGuardComponent = ({ children }) => {
    const { sessionState } = useContext(AuthState);
    const isLoggedIn = sessionState.token ? true : false;

    //Replace and erase the root url from history so
    //the user isn't chucked back to the app or login
    //screen when back tracking away from the main app root
    //or login screen
    return isLoggedIn === true ? (
        <Navigate to={RootPaths.APP_ROOT} replace={true} />
    ) : (
        <Navigate to={UIRoutePaths.LOGIN} replace={true} />
    );
};
