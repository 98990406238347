import { useContext, useEffect } from "react";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import { AppearanceSettingsServiceContext } from "../store/settings/appearance/appearanceSettingsService.context";
import { extendTheme } from "@mui/joy";

//themes
const baseTheme = extendTheme();

//Welcome to adjust
export const themes = extendTheme({
    cssVarPrefix: "joy",
    colorSchemes: {
        light: baseTheme.colorSchemes.light,
        dark: baseTheme.colorSchemes.dark,
    },
});

export const GlobalStylesProvider = ({ children }) => {
    const { subscribeToThemeService } = useContext(
        AppearanceSettingsServiceContext,
    );
    const { themeState } = subscribeToThemeService();

    useEffect(() => {
        document.documentElement.setAttribute(
            "data-joy-color-scheme",
            themeState.mode,
        );
    }, [themeState.mode]);

    return (
        <CssVarsProvider theme={themes} disableTransitionOnChange>
            <CssBaseline />
            {children}
        </CssVarsProvider>
    );
};
