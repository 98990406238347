import { AppearanceSettingsTypes } from "../constants";
import { themeInitialState } from "./constants";

export const useThemeSlice = (set) => ({
    ...themeInitialState,
    setTheme: (theme) =>
        set({
            [AppearanceSettingsTypes.THEME]: theme,
        }),
});
