import { Box, Grid, Typography } from "@mui/joy";
import { useNumberFormatMatrix } from "../../../../state/useNumberFormatMatrix.state";
import GridCells from "../utilComponents/GridCells";
import LoadingStatusSkeleton from "../../../utilComponents/loading/LoadingStatusSkeleton";
import LoadingStatusSpinner, {
    SpinnerSizes,
} from "../../../utilComponents/loading/LoadingStatusSpinner";

export default function LeaderBoard({ leaderBoardState }) {
    /*
{
        "rank": 1,
        "portfolio_return": .05,
        "total_players": 999,
        "results": [
            { "rank": 1, "username": "me", "portfolio_return": .05, "is_me": True},
            { "rank": 1, "username": "not me 1", "portfolio_return": .04, "is_me": False},
            { "rank": 1, "username": "not me 2", "portfolio_return": .03, "is_me": False},
            { "rank": 1, "username": "not me 3", "portfolio_return": .02, "is_me": False},
        ]
    }
    */
    const { leaderBoard, leaderBoardUpdating, error } = leaderBoardState;

    return (
        <Box>
            <h4 style={{ margin: 0 }}>
                <b>Leaderboard</b>
            </h4>
            <LoadingStatusSpinner loading={!leaderBoard} error={error}>
                <LeaderBoardGrid
                    leaderBoardData={leaderBoard}
                    leaderBoardUpdating={leaderBoardUpdating}
                    error={error}
                />
            </LoadingStatusSpinner>
        </Box>
    );
}

function LeaderBoardGrid({ leaderBoardData, leaderBoardUpdating, error }) {
    const { matrix, setCell, setRow, setColumn } = useNumberFormatMatrix(
        leaderBoardData.results.length,
        3,
    );
    const colSize = 4;

    return (
        <Box>
            <Typography>
                <b>{`You are ranked ${leaderBoardData.myRank} of ${leaderBoardData.totalPlayers} players.`}</b>
            </Typography>
            <Grid
                container
                sx={{
                    "& .MuiGrid-root": {
                        boxSizing: "border-box",
                        padding: "3px",
                        borderRight: "1px solid",
                        borderBottom: "1px solid",
                    },
                    borderTop: "1px solid",
                    borderLeft: "1px solid",
                    width: "75%",
                }}
            >
                <Grid xs={colSize}>
                    <Box>Rank</Box>
                </Grid>
                <Grid xs={colSize}>
                    <Box>Username</Box>
                </Grid>
                <GridCells.ColumnLabelGridCell
                    column={2}
                    label={"Return"}
                    updateFormatDisplay={setColumn}
                />

                {leaderBoardData.results.map((result, index) => (
                    <>
                        <Grid
                            xs={colSize}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: result.isMe
                                    ? "yellow"
                                    : "white",
                            }}
                        >
                            <Box>
                                {" "}
                                <LoadingStatusSpinner
                                    loading={leaderBoardUpdating}
                                    spinnerSize={SpinnerSizes.SMALL}
                                >
                                    {result.rank}{" "}
                                </LoadingStatusSpinner>
                            </Box>
                        </Grid>
                        <Grid
                            xs={colSize}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: result.isMe
                                    ? "yellow"
                                    : "white",
                            }}
                        >
                            <Box>{result.userId}</Box>
                        </Grid>
                        <GridCells.NumberGridCell
                            value={result.totalReturn}
                            row={index}
                            column={2}
                            precision={2}
                            makePercent={true}
                            showFormatted={matrix[index][2]}
                            updateFormatDisplay={setCell}
                            backgroundColor={result.isMe ? "yellow" : "white"}
                            updating={leaderBoardUpdating}
                        />
                    </>
                ))}
            </Grid>
        </Box>
    );
}
