import { useContext } from "react";
import { Grid, Sheet, Stack, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import LoadingStatusSkeleton, {
    SkeletonTypes,
} from "./utilComponents/loading/LoadingStatusSkeleton";
import { PriceService } from "../store/priceUpdates/priceUpdatesService.state";
import WhiteLogo from "../images/finimmerse-logo-white-md.png";

// import PriceComponent from "./priceComponents/PriceComponent";

export default function CardLink({ stock }) {
    const { priceService } = useContext(PriceService);
    const priceState = priceService.subscribeToPriceUpdatesByTicker(
        stock.ticker,
    );

    function indicator() {
        if (priceState?.priceChange > 0) {
            return "\u2197";
        } else if (priceState?.priceChange < 0) {
            return "\u2198";
        } else {
            return "\u2192";
        }
    }

    return (
        <>
            <Sheet
                component={Link}
                style={styles.cardStyles}
                key={stock.ticker}
                to={stock.ticker}
            >
                <Grid container spacing={0} style={{ width: "100%" }}>
                    <Grid item xs={12} sm={6}>
                        <LoadingStatusSkeleton
                            loading={priceState.loadingInitial}
                            skeletonType={SkeletonTypes.TEXT}
                            bgcolor={"#0cf"}
                        >
                            <Typography level="h3" sx={{ color: "#fff" }}>
                                {stock.ticker}
                            </Typography>
                        </LoadingStatusSkeleton>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                        <LoadingStatusSkeleton
                            loading={priceState.loadingInitial}
                            skeletonType={SkeletonTypes.TEXT}
                            bgcolor={"#0cf"}
                        >
                            <Typography
                                level="h4"
                                sx={{ color: "#fff", fontWeight: "normal" }}
                            >
                                $ {priceState.closePrice}
                            </Typography>
                        </LoadingStatusSkeleton>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "right" }}>
                        <LoadingStatusSkeleton
                            loading={priceState.loadingInitial}
                            skeletonType={SkeletonTypes.TEXT}
                            bgcolor={"#0cf"}
                        >
                            <Typography level="h4" sx={{ color: "#fff" }}>
                                {priceState.priceChange}
                                <span>&nbsp;</span>
                                {indicator()}
                            </Typography>
                        </LoadingStatusSkeleton>
                    </Grid>
                    <Grid item xs={12}>
                        &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                        &nbsp;
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <img
                            src={WhiteLogo}
                            alt="Finimmerse Logo"
                            height={50}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingStatusSkeleton
                            loading={priceState.loadingInitial}
                            skeletonType={SkeletonTypes.TEXT}
                            bgcolor={"#0cf"}
                        >
                            <Typography sx={{ color: "#fff" }}>
                                MCap:{" "}
                                {priceState?.metrics?.marketCap?.toFixed(0)}MM
                            </Typography>
                            <Typography sx={{ color: "#fff" }}>
                                Beta: {priceState?.metrics?.beta?.toFixed(2)}
                            </Typography>
                        </LoadingStatusSkeleton>
                    </Grid>
                </Grid>
                {/* <Typography level="h3" sx={{ color: "#fff" }}>
                    {stock.ticker}
                </Typography> */}

                {/* <PriceComponent ticker={stock.ticker} /> */}
            </Sheet>
        </>
    );
}

const styles = {
    cardStyles: {
        textDecoration: "none",
        // border: "4px solid #0cf",
        backgroundColor: "#0cf",
        display: "inline-block",
        width: "200px",
        height: "280px",
        marginRight: "20px",
        marginBottom: "20px",
        padding: "10px",
        borderRadius: "6px",
    },
    logo: {
        position: "relative",
        verticalAlign: "middle",
        margin: "0 auto",
        display: "inline-block",
    },
};
