import { Box, CircularProgress } from "@mui/joy";
import ErrorLoadingMessage from "./ErrorLoadingMessage";

export const SpinnerSizes = {
    SMALL: "sm",
    MEDIUM: "md",
    LARGE: "lg",
};

//type loading = boolean
export default function LoadingStatusSpinner(props) {
    const {
        loading,
        children,
        errorMsg = null,
        spinnerSize = SpinnerSizes.MEDIUM,
    } = props;

    return (
        <>
            {loading && <Spinner size={spinnerSize} thickness={1} />}
            {errorMsg && <ErrorLoadingMessage errorMessage={errorMsg} />}
            {!loading && !errorMsg && children}
        </>
    );
}

function Spinner({ size, thickness }) {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress size={size} thickness={thickness} />
        </Box>
    );
}
