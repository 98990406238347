import { useContext } from "react";
import { Link } from "react-router-dom";
import Chip from "@mui/joy/Chip";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";

import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import { AwardsService } from "../../state/awards.context";

export default function Navigation() {
    return (
        <List
            size="sm"
            sx={{
                "--ListItem-radius": "var(--joy-radius-sm)",
                "--List-gap": "4px",
            }}
        >
            <ListItem nested>
                {/* <ListSubheader sx={{ letterSpacing: "2px", fontWeight: "800" }}>
          Browse
        </ListSubheader> */}
                <List
                    aria-labelledby="nav-list-browse"
                    sx={{ "& .JoyListItemButton-root": { p: "8px" } }}
                >
                    <ListItem>
                        <ListItemButton component={Link} to="/app/cards">
                            <ListItemDecorator>
                                <PeopleRoundedIcon fontSize="small" />
                            </ListItemDecorator>
                            <ListItemContent>My Cards</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component={Link} to="/app/stocks">
                            <ListItemDecorator>
                                <PeopleRoundedIcon fontSize="small" />
                            </ListItemDecorator>
                            <ListItemContent>Stock Research</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component={Link} to="/app/tournaments">
                            <ListItemDecorator sx={{ color: "neutral.500" }}>
                                <AssignmentIndRoundedIcon fontSize="small" />
                            </ListItemDecorator>
                            <ListItemContent>Tournaments</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component={Link} to="/app/awards">
                            <ListItemDecorator sx={{ color: "neutral.500" }}>
                                <ArticleRoundedIcon fontSize="small" />
                            </ListItemDecorator>
                            <ListItemContent>Awards</ListItemContent>
                            <Chip variant="soft" color="warning" size="sm">
                                <AwardCount />
                            </Chip>
                        </ListItemButton>
                    </ListItem>
                </List>
            </ListItem>
        </List>
    );
}

function AwardCount() {
    const { subscribeToAwards } = useContext(AwardsService);
    const { awards } = subscribeToAwards();

    return <>{awards.length}</>;
}
