import { create } from "zustand";

//This keeps track of the index or number of times useState has been
//called within a component or whatever custom hook is attached to
//that component. This is so we can label multiple states by
//number as their changes are being logged so we have "value of state x is 'a'",
//"value of state x + 1 is 'b'", etc

//We are hosting this in a store as it's safer than updating a class instance
//plus we can use it to get indeces immediately without having
//to wait for react to go through the rendering lifecycle

export const useHookIndexerForLogging = create((set) => ({
    hookIndexByRegisteredComponentName: {},
    updateIndexer: (componentName) =>
        set((state) => {
            const indexerState = {
                ...state.hookIndexByRegisteredComponentName,
            };

            if (!indexerState[componentName]) {
                indexerState[componentName] = 0;
            }

            indexerState[componentName]++;

            return {
                hookIndexByRegisteredComponentName: indexerState,
            };
        }),
}));
