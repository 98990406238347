import { useContext, useEffect } from "react";
import { Box, Grid } from "@mui/joy";
import { ExperienceServiceProviderContext } from "../state/experience.context";
import ISODateStringFormatter from "./utilComponents/ISODateStringFormatter";
import { localizations } from "../utils/timeStampFormatTools";

export default function ExperienceScreen() {
    const { subscribeToExperienceTransactionHistory } = useContext(
        ExperienceServiceProviderContext,
    );
    const {
        transactionHistoryLoaded,
        loadingTransactionHistory,
        errorRetrievingTransactionHistory,
        beginTransactionHistoryFetch,
        getHistoryAsList,
    } = subscribeToExperienceTransactionHistory();

    useEffect(() => {
        if (!transactionHistoryLoaded) {
            beginTransactionHistoryFetch();
        }
    }, [transactionHistoryLoaded]);

    return (
        <Box>
            {loadingTransactionHistory && "loading"}
            {errorRetrievingTransactionHistory && "error"}
            {transactionHistoryLoaded && (
                <ExperienceGrid experienceHistory={getHistoryAsList()} />
            )}
        </Box>
    );
}

function ExperienceGrid({ experienceHistory }) {
    const col1Size = 5;
    const col2Size = 3;
    const col3Size = 4;

    return (
        <Box>
            <Grid
                container
                sx={{
                    "& .MuiBox-root": {
                        boxSizing: "border-box",
                        padding: "3px",
                        borderRight: "1px solid",
                        borderBottom: "1px solid",
                    },
                    borderTop: "1px solid",
                    borderLeft: "1px solid",
                }}
            >
                <Grid xs={col1Size}>
                    <Box>ID</Box>
                </Grid>
                <Grid xs={col2Size}>
                    <Box>Amount</Box>
                </Grid>
                <Grid xs={col3Size}>
                    <Box>Time Stamp</Box>
                </Grid>
                {experienceHistory.map((history) => (
                    <>
                        <Grid xs={col1Size}>
                            <Box>{history.id}</Box>
                        </Grid>
                        <Grid xs={col2Size}>
                            <Box>{history.amount}</Box>
                        </Grid>
                        <Grid xs={col3Size}>
                            <Box>
                                <ISODateStringFormatter
                                    isoDateString={history.txTimestamp}
                                    timelocalization={localizations.US}
                                />
                            </Box>
                        </Grid>
                    </>
                ))}
            </Grid>
        </Box>
    );
}
