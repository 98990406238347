import { Box, Divider, Option, Select, Switch, Typography } from "@mui/joy";
import { useContext, useMemo } from "react";
import { AppearanceSettingsServiceContext } from "../../../store/settings/appearance/appearanceSettingsService.context";
import { ThemeModes } from "../../../store/settings/appearance/theme/constants";
import { CurrencySettingsTypes } from "../../../store/settings/appearance/currency/constants";
import { textAlign } from "@mui/system";

export default function AppearanceSettingsComponent() {
    const settingsRowStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "10px",
    };
    //NOTE: had to specify the precision is for returns % FOR NOW to ease confusion
    //as the settings only apply to numbers wrapped in a NumberFormatter component
    return (
        <Box sx={{ width: "75%" }}>
            <Box sx={settingsRowStyle}>
                <Box>Theme:</Box>
                <Box>
                    <DarkModeToggle />
                </Box>
            </Box>
            <Divider />
            <Box sx={settingsRowStyle}>
                <Box>Precision (for leaderboard and returns grid values):</Box>
                <Box>
                    <PrecisionSettings />
                </Box>
            </Box>
            <Divider />
        </Box>
    );
}

function DarkModeToggle() {
    const { subscribeToThemeService } = useContext(
        AppearanceSettingsServiceContext,
    );
    const { themeState, toggleTheme } = subscribeToThemeService();

    return (
        <Typography
            component="label"
            onClick={() => {
                toggleTheme();
            }}
            endDecorator={
                <Switch
                    checked={themeState.mode === ThemeModes.DARK}
                    sx={{ ml: 1 }}
                />
            }
        >
            {themeState.mode === ThemeModes.DARK && "light mode"}
            {themeState.mode === ThemeModes.LIGHT && "dark mode"}
        </Typography>
    );
}

function PrecisionSettings() {
    const { subscribeToCurrencyService } = useContext(
        AppearanceSettingsServiceContext,
    );
    const { currencyState, updatePrecision } = subscribeToCurrencyService();

    const precision = currencyState[CurrencySettingsTypes.PRECISION];
    const defaultValue = useMemo(() => precision, []);

    const handleSelection = (e, value) => {
        updatePrecision(value);
    };

    return (
        <Select
            defaultValue={defaultValue}
            variant="outlined"
            onChange={handleSelection}
        >
            {new Array(9).fill(0).map((item, index) => (
                <Option key={index + 2} value={index + 2}>
                    {index + 2}
                </Option>
            ))}
        </Select>
    );
}
