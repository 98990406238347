import { generatePath } from "react-router";

//DESCRIPTION: This is a helper class to make applying actual data to dynamic parameters in an endpoint
//easier. You create an instance, and call applyArgsToDynamicParams which takes an endpoint and
//an arbitrary number of data to plug into it's dynamic params (NOTE: the data that is being applied
//MUST be in the order of appearance of the dynamic parameters in order to work consistantly).

//Demo: const builder = URLBuilder.create({ENDPOINT: "api/v2/:dynamic/abc/:param"});
//builder.applyArgsToDynamicParams("api/v2/:dynamic/abc/:param", 1,2)
//expected output: api/v2/1/abc/2

//interface InterpolationObject{ params: Object}

//interface InterpolationObjects{ [endpoint: string] : InterpolationObject; }

//interface EndpointObject{ [endpointKey: string] : string } e.g. { TOURNAMENTS: "/api/v1/torunaments/:id"}

export class URLBuilder {
    //private
    interpolationObjects;
    //private
    fullBaseURL;

    constructor(interpolationObjects, fullBaseURL = "") {
        this.interpolationObjects = interpolationObjects;
        this.fullBaseURL = fullBaseURL;
    }

    static create(endpointObject, fullBaseURL = "") {
        const interpolationObjects = Object.keys(endpointObject).reduce(
            (interpolationObjects, endpointKey) => {
                const endpoint = endpointObject[endpointKey];
                interpolationObjects[endpoint] =
                    URLBuilder.createInterpolationObjectFromEndpoint(endpoint);
                return interpolationObjects;
            },
            {},
        );

        return new URLBuilder(interpolationObjects, fullBaseURL);
    }

    //private
    static createInterpolationObjectFromEndpoint(endpoint) {
        return {
            params: URLBuilder.createDynamicParamsObjectFromEndpoint(endpoint),
        };
    }

    //private
    static createDynamicParamsObjectFromEndpoint(endpoint) {
        return endpoint.split("/").reduce((params, str) => {
            const regex = /^:[a-zA-Z]/;
            //does it begin with a colon and a letter?
            const isDynamic = regex.test(str);
            if (isDynamic) {
                params[str.substring(1)] = null;
            }
            return params;
        }, {});
    }

    //private
    getParamsByEndpoint(endpoint) {
        return this.interpolationObjects[endpoint]
            ? { ...this.interpolationObjects[endpoint].params }
            : false;
    }

    //private
    validateArgsPassed(params, args) {
        return Object.keys(params).length === args.length;
    }

    //private
    saturateParamsWithArgs(args, params) {
        const paramList = Object.keys(params);
        return args.reduce((saturated, arg, index) => {
            saturated[paramList[index]] = arg;
            return saturated;
        }, {});
    }

    applyArgsToDynamicParams(endpoint, ...args) {
        const params = this.getParamsByEndpoint(endpoint);

        if (!params) {
            throw new Error("url not found", endpoint);
        }

        if (!this.validateArgsPassed(params, args)) {
            throw new Error(
                `invalid args passed: expected length ${params.length} received length ${args.length}`,
            );
        }

        const paramsWithValues = this.saturateParamsWithArgs(args, params);

        const pathWithoutBaseURL = endpoint.substring(this.fullBaseURL.length);

        return `${this.fullBaseURL}${generatePath(pathWithoutBaseURL, paramsWithValues)}`;
    }
}
