import { Box, Grid, Typography } from "@mui/joy";
import GridCells from "../utilComponents/GridCells";
import NumberFormatter from "../../../utilComponents/NumberFormatter";
import { useNumberFormatMatrix } from "../../../../state/useNumberFormatMatrix.state";
import { useDefaultPrecisionFromSettings } from "../../../../state/useDefaultPrecisionFromSettings.state";
import NumberFormatDisplayTools from "../utilComponents/NumberFormatDisplayTools";
import LoadingStatusSpinner from "../../../utilComponents/loading/LoadingStatusSpinner";

export default function CardDeck({ cardDeckState }) {
    const { returnsList, updating, error } = cardDeckState;
    return (
        <Box>
            <h4 style={{ margin: 0 }}>
                <b>My Portfolio's Return</b>
            </h4>
            <LoadingStatusSpinner
                loading={!returnsList.length}
                errorMsg={error ? "error updating returns" : null}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        columnGap: "1em",
                    }}
                >
                    <Aggregate returnsList={returnsList} />
                    <ReturnsGrid
                        returnsList={returnsList}
                        updating={updating}
                    />
                </Box>
            </LoadingStatusSpinner>
        </Box>
    );
}

function Aggregate({ returnsList }) {
    const returnTotal = returnsList.reduce((sum, returnObj) => {
        sum += returnObj.return;
        return sum;
    }, 0);

    return (
        <Box>
            <Typography>
                <b>
                    Total Return:{" "}
                    <NumberFormatter value={returnTotal} makePercent={true} />
                </b>
            </Typography>
        </Box>
    );
}

function ReturnsGrid({ returnsList, updating }) {
    const precisionUserSetting = useDefaultPrecisionFromSettings();
    const { matrix, setCell, setRow, setColumn } = useNumberFormatMatrix(
        returnsList.length,
        5,
    );

    const columnLabels = [
        "Ticker",
        "Last Price",
        "Open Price",
        "Change",
        "Return",
    ];

    return (
        <Grid
            container
            columns={24}
            sx={{
                "& .MuiGrid-root": {
                    boxSizing: "border-box",
                    padding: "3px",
                    borderRight: "1px solid",
                    borderBottom: "1px solid",
                },
                borderTop: "1px solid",
                borderLeft: "1px solid",
                width: { sm: "85%", xs: "100%" },
            }}
        >
            <Grid xs={4} />
            {columnLabels.map((label, index) => (
                <GridCells.ColumnLabelGridCell
                    column={index}
                    label={label}
                    updateFormatDisplay={setColumn}
                    isNumberColumn={label !== "Ticker"}
                />
            ))}
            {returnsList.map((ret, index) => (
                <>
                    <Grid
                        xs={4}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <NumberFormatDisplayTools.RowFormatting
                            row={index}
                            updateFormatDisplay={setRow}
                        />
                    </Grid>
                    <Grid
                        xs={4}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>{ret.ticker}</Box>
                    </Grid>
                    <GridCells.NumberGridCell
                        value={ret.price}
                        row={index}
                        column={1}
                        precision={precisionUserSetting}
                        showFormatted={matrix[index][1]}
                        updateFormatDisplay={setCell}
                        updating={updating}
                    />
                    <GridCells.NumberGridCell
                        value={ret.openPrice}
                        row={index}
                        column={2}
                        precision={precisionUserSetting}
                        showFormatted={matrix[index][2]}
                        updateFormatDisplay={setCell}
                        updating={updating}
                    />
                    <GridCells.NumberGridCell
                        value={ret.change}
                        row={index}
                        column={3}
                        precision={precisionUserSetting}
                        showFormatted={matrix[index][3]}
                        updateFormatDisplay={setCell}
                        updating={updating}
                    />
                    <GridCells.NumberGridCell
                        value={ret.return}
                        row={index}
                        column={4}
                        precision={precisionUserSetting}
                        makePercent={true}
                        showFormatted={matrix[index][4]}
                        updateFormatDisplay={setCell}
                        updating={updating}
                    />
                </>
            ))}
        </Grid>
    );
}
