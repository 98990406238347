import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { router } from "./router/fantasy-stocks-router";
import { RouterProvider } from "react-router-dom";
import { AuthStateProvider } from "./state/auth.context";
import ConnectionStatusMessage from "./components/ConnectionStatusMessage";
import { proxyForUseState } from "./store/logging/useStateProxyForLogging";
import { LoggerServiceProviderGuard } from "./store/logging/loggerService.state";
const root = ReactDOM.createRoot(document.getElementById("root"));
//proxyForUseState();
root.render(
    <React.StrictMode>
        <LoggerServiceProviderGuard>
            <AuthStateProvider>
                <RouterProvider router={router} />
                {/* <App /> */}
                {<ConnectionStatusMessage />}
            </AuthStateProvider>
        </LoggerServiceProviderGuard>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
