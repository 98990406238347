import { AppearanceSettingsTypes } from "../constants";
import { currencyInitialState } from "./constants";

export const useCurrencySlice = (set) => ({
    ...currencyInitialState,
    setPrecision: (precision) =>
        set((state) => mergeWithState(precision, state)),
});

const mergeWithState = (updateWith, state) => {
    return {
        ...state,
        [AppearanceSettingsTypes.CURRENCY]: {
            ...state[AppearanceSettingsTypes.CURRENCY],
            ...updateWith,
        },
    };
};
