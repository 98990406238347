import axios from "axios";
import { TOKEN_IN_LOCAL_STORAGE_LABEL } from "../../globalConsts.constants";

//Wrapper class for whatever api-lib we're using
//It's main reason to exist is for the api services to use
//and return a more consitant data structure for the react
//components or hooks/state mgmt to rely on instead of
//creating their own

//Also reduces the code needed to implement api service functions
//so lets say instead of having to tediously write error handling, we
//have all that built into here

//TODO: implement this in more services. For the sake of prototyping
//we are only implementing/testing this in a select few areas that aren't
//as impactful or complex. When we get a chance, best to implement early
//post-prototype as the codebase should still be simple enough to
//refactor and test

//interface Options = {"needsAuth": boolean}

//for options param passed in, it MUST follow the Options interface

export const CONTEXT_OPTIONS = {
    NEEDS_AUTH: "needsAuth",
};

export class APIContext {
    service = axios;
    interceptors = axios.interceptors;

    addAuthHeader() {
        if (!localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL)) {
            throw new Error(`cannot find user token`);
        }

        return this.service.create({
            headers: {
                Authorization: `Bearer ${localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL)}`,
            },
        });
    }

    async get(endpoint, options = {}) {
        let res = {};
        let response = {};

        try {
            response = await (options[CONTEXT_OPTIONS.NEEDS_AUTH]
                ? this.addAuthHeader().get(endpoint)
                : this.service.get(endpoint));
            res = { data: response.data, status: response.status };
        } catch (e) {
            res = { error: e, status: e.status };
        }

        return res;
    }

    async post(endpoint, payload, options = {}) {
        let res = {};
        let response = {};

        try {
            response = await (options[CONTEXT_OPTIONS.NEEDS_AUTH]
                ? this.addAuthHeader().post(endpoint, payload)
                : this.service.post(endpoint, payload));
            res = { data: response.data || null, status: response.status };
        } catch (e) {
            res = { error: e, status: e.status };
        }

        return res;
    }

    async delete(endpoint, options = {}) {
        let res = {};
        let response = {};

        try {
            response = await (options[CONTEXT_OPTIONS.NEEDS_AUTH]
                ? this.addAuthHeader().delete(endpoint)
                : this.service.delete(endpoint));
            res = { data: response.data || null, status: response.status };
        } catch (e) {
            res = { error: e, status: e.status };
        }

        return res;
    }

    //type interceptorCallBack = (response) => AxiosResponse<any, any>
    //type errorCallback = (error) => any
    addResponseInterceptor(interceptorCallBack, errorCallBack = null) {
        if (!errorCallBack) {
            this.interceptors.response.use(interceptorCallBack);
        } else {
            this.interceptors.response.use(interceptorCallBack, errorCallBack);
        }
    }

    ejectResponseInterceptor(interceptorID) {
        this.interceptors.response.eject(interceptorID);
    }
}
